<template>
  <div class="mobile-home m-navigation-bar">
    <!-- <div class="mobile-home-hint" @click="show = true">
      <div class="hint-button">公告</div>
      <div class="time">11-21</div>
      <div class="text">中福艺术平台积极响应国家网络安全和信息化委员会……</div>
    </div> -->
    <img
      class="mobile-home-img1"
      src="@/assets/mobile/Rectangle 2@2x.png"
      alt=""
    />
    <div class="mobile-home-img2">
      <div class="mobile-home-img2-text1">Digital Art Fair 2022 圆满结束</div>
      <div class="mobile-home-img2-text2">2022.11.8</div>
      <van-button class="mobile-home-img2-button" type="danger" @click="$router.push('/homeDaf')"
        >查看详情</van-button
      >
    </div>
    <div class="video">
        <video src="/video/video.mov" muted autoplay height="100%" width="100%"
        controls />
      </div>
    <div class="mobile-home-img3">
      <div class="mobile-home-img3-text">
        中国 | 中国香港 | 中国澳门 | 老挝 | 柬埔寨 | 日本 | 英国
      </div>
    </div>
    <div class="mobile-home-img4">
      <div class="mobile-home-img4-title">热销系列</div>
      <div class="mobile-home-img4-content-painting">
        <div class="title">中国画系列数字藏品</div>
        <div class="text">此系列来自于《贞松永茂》以及《画坛巨擘》两本书画合璧册。拥有书画艺术的珍贵同时凸显中国文化、历史、道德、教育、学识等更方面的价值。从题材看：书法中真、草、棣、隶、篆，绘画中山水、任务、花鸟、走兽，应有尽有。从技法看：工笔、写意、水墨、 设色，诗、词、题、赋，一应俱全。从中可见，二十世纪四十年代中国书画家作品大致面貌，对研究我国近现代画史具有重要参考价值。</div>
          <van-button class="mobile-home-img4-content-button" type="danger" @click="marketBtn"
            >查看系列</van-button
          >
      </div>
    </div>
    <div class="mobile-home-img5">
      <div class="mobile-home-img5-title">什么是数字艺术藏品</div>
      <div class="mobile-home-img5-text">数字藏品是使用区块链技术进行唯一性标识的经数字化的特定作品、艺术品、有价值的物（含网络虚拟财产、财产性权益等）。 数字藏品为虚拟商品，您在购买时需特别同意，相关商品不接受退换货。每个数字藏品都映射着特定区块链上的唯一序列号，不可篡改、不可分割，也不能互相替代。每一个数字藏品都代表特定作品、艺术品和商品或其限量发售的单个数字复制品，记录着其不可篡改的链上权利。数字藏品有特定作品、艺术品和商品的实际价值做支撑，不具备支付功能等任何货币属性。</div>
    </div>
    <div class="mobile-home-img6">
      <div class="mobile-home-img6-title">什么是数字艺术藏品</div>
      <div class="mobile-home-img6-box">
        <img class="img1" src="@/assets/mobile/Bitmap Copy@2x.png" alt="">
        <div class="mobile-home-img6-box-title">1、版权确权及溯源</div>
        <div class="mobile-home-img6-box-text">通过区块链技术来解决数字艺术品版权和溯源的问题，通过智能合约与业务应用层内实现逻辑处理和数据整合，让版权归属与收入分配的透明度达到新的台阶。</div>
      </div>
      <div class="mobile-home-img6-box">
        <img class="img2" src="@/assets/mobile/Bitmap@2x.png" alt="">
        <div class="mobile-home-img6-box-title">2、为行业降本增效</div>
        <div class="mobile-home-img6-box-text">有效提高权属问题产生版权纠纷的解决效率，因版权区块链证据公信力强，可以减少大量诉讼周期和相关费用，普惠每一位创作者。</div>
      </div>
      <div class="mobile-home-img6-box">
        <img class="img3" src="@/assets/mobile/Bitmap@2x (1).png" alt="">
        <div class="mobile-home-img6-box-title">3、透明式版权保护</div>
        <div class="mobile-home-img6-box-text">版权保护更加清晰透明，集权益存证、作品确权、数量控制以及盗版防范为一体，让原创者“不出门”实现版权保护。</div>
      </div>
      <div class="mobile-home-img6-box">
        <img class="img4" src="@/assets/mobile/Bitmap Copy 2@2x.png" alt="">
        <div class="mobile-home-img6-box-title">4、强大的技术支撑</div>
        <div class="mobile-home-img6-box-text">平台立足于区块链技术在知识产权领域的应用，强大的加密算法和云计算能力支持每一次安全可靠的链上互动。</div>
      </div>
    </div>
    <van-dialog v-model="show" :showConfirmButton="false" :closeOnClickOverlay="true">
      <div class="hint-content">
        <img class="title" src="@/assets/mobile/title-name.png" alt="">
        <img class="esc" src="@/assets/mobile/Path.png" alt="" @click="show = false">
        <div class="title-name"></div>
        <div class="time">2022.11.8</div>
        <div class="text">尊敬的用户您好！中福艺术平台为积极响应国家网络安全和信息化委员会办公室《关于进一步防范和处置虚拟货币交易炒作风险的通知》的号召，特对您在我们平台上的数字艺术品交易做温馨提示：本平台中所有数字艺术品均应通过NFT技术将数字摘要信息存储在区块链上，您作为数字艺术品的用户（艺术家、入驻商家、收藏者甚或投资者）应当树立正确的理念，明确数字艺术品是文化的载体而不是金融的工具，准确识别基于NFT技术的数字艺术品交易风险，希望您在收藏、使用、处分、购买、售卖数字艺术品及其相关权利时合理评估自身的风险识别能力和风险承担能力，hunter将与您一同关注数字艺术品交易的相关风险。</div>
        <div class="btn" @click="show = false">知道了</div>
      </div>
</van-dialog>
  </div>
</template>

<script>
import Utils from '@/common/utils.js'
export default {
  data() {
    return {
      show: false,
    }
  },
  methods: {
    marketBtn() {
      Utils.$emit('Painting');
      this.$router.push('/market?type=Painting')
      window.scrollTo(0, 0)
    }
  }
}
</script>

<style lang="less" scoped>
.mobile-home {
  // font-size: .22rem;
  .mobile-home-hint {
    width: 100%;
    height: .49rem;
    background: #FCF9F5;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    font-size: .09rem;
    padding-left: .28rem;
    .hint-button {
      width: .39rem;
      height: .18rem;
      background: url('../../assets/mobile/Rectangle@236x.png') no-repeat;
      background-size: 100% 100%;
      margin-right: .14rem;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .time {
      margin-right: .13rem;
    }
  }
  .mobile-home-img1 {
    width: 100%;
    height: 5.16rem;
    margin-bottom: 0.18rem;
  }
  .mobile-home-img2 {
    position: relative;
    width: 100%;
    height: 2.16rem;
    position: relative;
    background: url('../../assets/mobile/Group 15@2x.png') no-repeat;
    background-size: 100% 100%;
    font-size: 0.13rem;
    padding-top: 0.6rem;
    padding-left: 0.24rem;
    box-sizing: border-box;
    margin-bottom: 0.2rem;
    .mobile-home-img2-text1 {
      font-size: 0.22rem;
    }
    .mobile-home-img2-button {
      position: absolute;
      bottom: 0.22rem;
      left: 50%;
      transform: translate(-50%, 0);
      width: 1.18rem;
      height: 0.43rem;
      font-size: 0.14rem;
      background: url('../../assets/mobile/Group 11@2x.png') no-repeat;
      box-sizing: border-box;
      background-size: 100% 100%;
      border: none;
      border-radius: 0.25rem;
    }
  }
  .mobile-home-img3 {
    width: 100%;
    height: 2.62rem;
    text-align: center;
    box-sizing: border-box;
    padding-top: 0.37rem;
    background: url('../../assets/mobile/Group 3@2x.png') no-repeat;
    background-size: 100% 100%;
    margin-bottom: 0.24rem;
    .mobile-home-img3-text {
      font-size: 0.11rem;
    }
  }
  .mobile-home-img5 {
    background: url('../../assets/mobile/Group 7 Copy 2@2x.png') no-repeat;
    width: 100%;
    height: 2.85rem;
    box-sizing: border-box;
    background-size: 100% 100%;
    padding: .35rem .21rem 0 .29rem;
    margin-bottom: .09rem;
    .mobile-home-img5-title {
      font-size: .19rem;
      font-weight: 900;
      margin-bottom: .43rem;
      text-align: center;
    }
    .mobile-home-img5-text {
      font-size: .11rem;
      line-height: .15rem;
      // width: 3.25rem;
    }
  }
  .mobile-home-img6 {
    background: url('../../assets/mobile/Group 7 Copy 2@2x (1).png') no-repeat;
    width: 100%;
    height: 9.72rem;
    box-sizing: border-box;
    background-size: 100% 100%;
    margin-bottom: .11rem;
    padding: .35rem ;
    display: flex;
    flex-direction: column;
    align-items: center;
    .mobile-home-img6-title {
      font-size: .19rem;
      font-weight: 900;
      // text-align: center;
      margin-bottom: .39rem;
    }
    .mobile-home-img6-box {
      font-size: .11rem;
      line-height: .15rem;
      text-align: center;
      width: 1.71rem;
      .img1 {
        width: .48rem;
        height: .45rem;
        margin-bottom: .4rem;
      }
      .img2 {
        width: .45rem;
        height: .47rem;
        margin-bottom: .2rem;
      }
      .img3 {
        width: .39rem;
        height: .51rem;
        margin-bottom: .2rem;
      }
      .img4 {
        width: .41rem;
        height: .42rem;
        margin-bottom: .26rem;
      }
      .mobile-home-img6-box-title {
        font-size: .14rem;
        margin-bottom: .05rem;
        font-weight: 900;
        img:nth-child(2) {
        width: .45rem;
        height: .2rem;
        margin-bottom: .05rem
      }
      img:nth-child(4) {
        width: .41rem;
        height: .42rem;
        margin-bottom: .26rem;
      }
      }
      .mobile-home-img6-box-title:nth-child(3) {
        margin-bottom: .09rem;
      }
      .mobile-home-img6-box-title:nth-child(2) {
        margin-bottom: .06rem;
      }
      .mobile-home-img6-box-text {
        height: 1.17rem;
        margin-bottom: .14rem;
      }
      .mobile-home-img6-box-text:nth-child(2) {
        height: .94rem;
      }
      .mobile-home-img6-box-text:nth-child(3) {
        height: .94rem;
      }
      .mobile-home-img6-box-text:nth-child(4) {
        height: 94rem;
      }
    }
  }
  .hint-content {
    position: relative;
    height: 4.83rem;
    position: relative;
    box-sizing: border-box;
    padding-top: .9rem;
    padding-left: .27rem;
    .esc {
      width: .16rem;
      height: .16rem;
      position: absolute;
      right: .21rem;
      top: .23rem;
    }
    .title {
      width: .8rem;
      position: absolute;
      top: .39rem;
      left: .27rem;
    }
    .title-name {
      height: .02rem;
      background-color: #f1f1f1;
      margin-bottom: .1rem;
      width: 2.5rem;
    }
    .time {
      font-size: .13rem;
      margin-bottom: .18rem;
    }
    .text {
      padding-right: .27rem;
      font-size: .09rem;
color: #3F3F3F;
line-height: .13rem;
margin-bottom: 1.23rem;
    }
    .btn {
      width: 1.18rem;
      height: .43rem;
      background: linear-gradient(180deg, #FF8787 0%, #FF3030 100%);
      border-radius: .34rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: .14rem;
      position: absolute;
      bottom: .43rem;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
  .video {
    width: 100%;
    height: 2.115rem;
    margin-bottom: .2rem;
  }
  .mobile-home-img4-content-painting {
    width: 100%;
    height: 6.51rem;
    box-sizing: border-box;
    background: url('../../assets/mobile/LAYER NFT Copy 6.png') no-repeat;
    background-size: 100% 100%;
    position: relative;
    margin-bottom: .34rem;
    .mobile-home-img4-content-button {
      width: 1.18rem;
      height: .43rem;
      box-sizing: border-box;
      background: linear-gradient(#FF8787, #FF3030);
      border-radius: .34rem;
      border: none;
      position: absolute;
      bottom: .33rem;
      left: 1.29rem;
      font-size: .14rem;
      font-weight: 400;
    }
    .title {
        font-size: .31rem;
        font-weight: 900;
        position: absolute;
        top: .56rem;
        left: .49rem;
      }
      .text {
        width: 2.99rem;
        font-size: .11rem;
        font-weight: 400;
        line-height: .15rem;
        position: absolute;
        top: 1.37rem;
        left: .52rem;
      }
  }
}
</style>
