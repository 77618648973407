<template>
  <div id="app">
    <!-- <pc-navigation-bar></pc-navigation-bar> -->
    <!-- <router-view/> -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
    <!-- <pc-footer></pc-footer> -->
  </div>
</template>
  <script>
  // import PcNavigationBar from '@/components/pc/navigation-bar.vue'
  // import PcFooter from '@/components/pc/footer.vue'
  export default {
    components: {
      // PcNavigationBar,
      // PcFooter
    },
    data() {
      return {

      }
    },
    created() {
    },
    methods: {
    },
  }
  </script>
<style lang="less">
</style>
