<template>
  <div class="m-footer">
    <ul>
      <li class="li1">
        <p class="title">联系我们</p>
      </li>
      <li class="li2">
        <img src="@/assets/mobile/Shape@2x (1).png" alt=""> （852）3069-7821
      </li>
      <li class="li3">
        <img src="@/assets/mobile/Shape@2x (2).png" alt=""> service@hunter-digital.io
      </li>
    </ul>
    <div class="m-footer-options">
      <div class="item">
        <p>帮助中心</p>
        <p class="text" @click="$router.push('/course')">购买指南</p>
      </div>
      <div class="item">
        <p>合作伙伴</p>
        <p class="text">香港大学</p>
        <p class="text">华为云</p>
      </div>
    </div>
    <div class="m-footer-text">© Copyright 2022 Hunter Digital Limited - All Rights Reserved</div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
  .m-footer {
    box-sizing: border-box;
    width: 100%;
    background-color: #fff;
    font-size: .1rem;
    padding-left: .87rem;
    padding-top: .34rem;
    padding-bottom: .1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    ul li {
      display: flex;
      align-items: center;
    }
    .li1 .title {
      color: #979797;
      font-size: .11rem;
      margin-bottom: .1rem;
    }

    .li2 {
      margin-bottom: .09rem;
      img {
        width: .17rem;
        height: .17rem;
        margin-right: .03rem;
      }
    }
    .li3 {
      margin-bottom: .24rem;
      img {
        width: .17rem;
        height: .17rem;
        margin-right: .03rem;
      }
    }
    .m-footer-text {
      font-size: .07rem;
    }
    .m-footer-options {
      display: flex;
      margin-bottom: .33rem;
      .item {
        margin-right: .4rem;
        p {
          color: #979797;
          font-size: .11rem;
          margin-bottom: .05rem;
        }
        .text {
          color: #3F3F3F;
          margin-bottom: .08rem;
        }
      }
    }
  }
</style>