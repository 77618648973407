import Vue from 'vue'
import VueRouter from 'vue-router'
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
import Home from '../views/home.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      name: '首页',
    }
  },
  {
    path: '/homeDaf',
    name: 'homeDaf',
    component: () => import(/* webpackChunkName: "about" */ '../views/home-daf.vue'),
    meta: {
      name: '首页',
    }
  },
  {
    path: '/industry',
    name: 'Industry',
    component: () => import(/* webpackChunkName: "about" */ '../views/industry.vue'),
    meta: {
      name: '行业应用',
    }
  },
  {
    path: '/case',
    name: 'Case',
    component: () => import(/* webpackChunkName: "about" */ '../views/case.vue'),
    meta: {
      name: '合作案例',
    }
  },
  {
    path: '/market',
    name: 'Market',
    meta: {
      name: '数字藏品市场', // 添加导航栏高亮标识
      keepAlive: true
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/market.vue')
  },
  {
    path: '/details',
    name: 'Details',
    meta: {
      name: '数字藏品市场', // 添加导航栏高亮标识
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/details.vue')
  },
  {
    path: '/pay',
    name: 'Pay',
    meta: {
      name: '数字藏品市场', // 添加导航栏高亮标识
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/pay.vue')
  },
  {
    path: '/order',
    name: 'Order',
    meta: {
      name: '数字藏品市场', // 添加导航栏高亮标识
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/order-list.vue')
  },
  {
    path: '/course',
    name: 'Course',
    meta: {
      name: '用户教程', // 添加导航栏高亮标识
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/course.vue')
  },
]
  
const router = new VueRouter({
  routes
})

export default router
