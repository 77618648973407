import router from "./index";
import store from "../store/index"
import { Message } from 'element-ui'



// 导航守卫
router.beforeEach((to, from, next) => {
  if(to.path !== '/market' && from.path !== '/details') {
    window.scrollTo(0,0);
  }
  const token = store.state.userInfo.token
  const url = to.path
  const whiteList = ['/details', '/order', '/pay'] 
  // 已登录放行
  if(token && whiteList.includes(url)){
      next()
      return
  }
  
  // 未登录拦截
  if(!token && whiteList.includes(url)){
    if(from.path === '/market') {
      // store.commit('setLoginShow',true)
      Message({
        message: '请先登录',
        type: 'warning',
        duration: 5 * 1000,
      })
      store.commit('setLoginShow',true)
      return
    }
    next('/market')
    // store.commit('setLoginShow',true)
    Message({
      message: '请先登录',
      type: 'warning',
      duration: 5 * 1000,
    })
    store.commit('setLoginShow',true)
    return
  }
  next()
})