import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/common/index.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/components/pc/index'
import '@/components/mobile/index'
import './router/permission'
import Vant from 'vant'
import 'vant/lib/index.css'
import i18n from '@/components/pc/lang/index'
import formatTime from './common/fileters'


Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(Vant)
const isMobile = navigator.userAgent.match(
  /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
)
Vue.prototype.$isMobile = isMobile
Vue.prototype.formatTime = formatTime
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
  created() {
    // 实例创建完成后被立即调用rem换算方法,解决内容闪现问题

    if (isMobile) {
      //移动端使用
      setRem()
      window.addEventListener('resize', setRem) //浏览器窗口大小改变时调用rem换算方法
    } else {
      //pc端使用
      setRemPC()
      window.addEventListener('resize', setRemPC) //浏览器窗口大小改变时调用rem换算方法
    }
  },
}).$mount('#app')

// 手机端
function setRem() {
  var whdef = 100 / 375 // 表示750的设计图,使用100PX的默认值
  var bodyWidth = document.body.clientWidth // 当前窗口的宽度
  var rem = bodyWidth * whdef // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
  document.getElementsByTagName('html')[0].style.fontSize = rem + 'px'
}
//pc端
//rem计算
function setRemPC() {
  var width = 100 / 1920 // 表示1454的设计图,使用100PX的默认值
  var bodyWidth = document.body.clientWidth // 当前窗口的宽度
  if (bodyWidth >= 1000 && bodyWidth <= 1920) {
    // rem适配 屏幕缩放太大或太小会错乱 加个判断在多少尺寸之内才会变化
    var rem = bodyWidth * width // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
    document.getElementsByTagName('html')[0].style.fontSize = rem + 'px'
  } else {
    document.getElementsByTagName('html')[0].style.fontSize = 75 + 'px' // 如果不在判断尺寸之内就设置为基础值, 这个基础值100对应第一个100
  }
}
