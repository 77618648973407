<template>
  <div class="mobile-navigation-bar">
    <img class="img1" src="@/assets/mobile/Group 2@2x.png" alt="">
    <img class="img2" src="@/assets/mobile/Group 10@2x.png" alt="" @click="show = true">
    <van-popup v-model="show" round @closed="closed" :close-on-popstate="true" :style="{ width: '60%', height: '100%' }" position="right">
      <div class="popup-content">
        <img src="@/assets/mobile/Group 2@2x.png" alt="">
        <ul>
          <li v-for="(item, index) in list" :key="index" @click="pathBtn(item), index">{{ item.name }}</li>
        </ul>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
    data() {
      return {
        show: false,
        key: 0,
        routerPath: '',
        list: [
          {
          name: '首页',
          path: '/',
        },
        {
          name: '行业应用',
          path: '/industry',
        },
        {
          name: '合作案例',
          path: '/case',
        },
        {
          name: '用户教程',
          path: '/course',
        },
        {
          name: '数字藏品市场',
          path: '/market',
        },
        ]
      }
    },
    methods: {
      pathBtn(item, index) {
        this.routerPath = item.path
        this.show = false
      },
      closed() {
        this.$router.push(this.routerPath)
        this.routerPath = ''
      }
    }
}
</script>

<style lang="less" scoped>
  .mobile-navigation-bar {
    width: 100%;
    height: .57rem;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: .28rem;
    padding-right: .19rem;
    box-sizing: border-box;
    position: fixed;
    z-index: 10;
    .img1 {
      width: 1.27rem;
      height: .33rem;
    }
    .img2 {
      width: .26rem;
      height: .25rem;
    }
    .popup-content {
      box-sizing: border-box;
      padding: 1.1rem 0 0 .24rem;
      img {
        width: 1.27rem;
        height: .33rem;
        margin-bottom: .52rem;
      }
      ul li {
        margin-bottom: .24rem;
        font-size: .14rem;
      }
    }
  }
</style>