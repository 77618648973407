<template>
  <div class="pc-footer min-width">
    <div class="top">
      <ul>
      <li style="color: #979797; font-size: .19rem;">联系我们</li>
      <li><img src="@/assets/pc/Shape@2x (1).png">（852）3069-7821</li>
      <li><img style="height: .24rem; margin-right: .13rem;" src="@/assets/pc/Shape@2x (2).png">service@hunter-digital.io</li>
    </ul>
    <ul>
      <li>合作伙伴</li>
      <li>香港大学</li>
      <li>华为云</li>
    </ul>
    <ul>
      <li>帮助中心</li>
      <li @click="$router.push('/course')">购买指南</li>
    </ul>
    
    </div>
    <div class="bottom">© Copyright 2022 Hunter Digital Limited - All Rights Reserved</div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
  .pc-footer {
    width: 100%;
    height: 3.6rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: .72rem;
    padding-bottom: .32rem;
    background-color: #fff;
    font-size: .18rem;
    color: #191919;
    font-weight: 400;
    .top {
      display: flex;
      ul {
        padding: 0 .555rem;
        li {
          display: flex;
          align-items: center;
          img {
            width: .32rem;
            height: .32rem;
            margin-right: .04rem;
          }
        }
      }
      ul li{
        margin-bottom: .2rem;
      }
      ul li:nth-child(1) {
        color: #979797;
      }
      ul:nth-child(1) li {
        margin-bottom: .24rem;
      }
      ul:nth-child(1) li:nth-child(1) {
        color: #191919;
      }
    }
  }
</style>