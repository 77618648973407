<template>
  <div class="pc-navigation-bar min-width">
    <div class="bx">
      <img src="@/assets/pc/Group 2@2x.png" alt="">
    <ul>
        <li v-for="(item, index) in list" :key="index">
        <router-link :to="item.path" :style="item.name === $route.meta.name ? 'color: #000; font-weight: 500;' : ''">
        {{ item.name }}
        </router-link>
      </li>
    </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          name: '首页',
          path: '/',
        },
        {
          name: '行业应用',
          path: '/industry',
        },
        {
          name: '合作案例',
          path: '/case',
        },
        {
          name: '用户教程',
          path: '/course',
        },
        {
          name: '数字藏品市场',
          path: '/market',
        },
      ],
    }
  },
}
</script>

<style lang="less" scoped>
  .pc-navigation-bar {
    width: 100%;
    box-sizing: border-box;
    backdrop-filter: blur(.08rem);
    font-size: .19rem;
    font-weight: 400;
    color: #979797;
    position: fixed;
    background-color: #fff;
    z-index: 10;
    display: flex;
    .bx {
      width: 12.4rem;
      margin: 0 auto;
      display: flex;
      align-items: center;
    }
    a {
      color: #979797;
    }
    a:hover {
        color: #000;
        font-weight: 500;
      }
    ul {
      display: flex;
      li {
        // margin: 0 .48rem;
        width: 1.2rem;
        margin-right: .48rem;
        text-align: center;
      }
    }
    img {
      width: 3.28rem;
      height: .73rem;
      margin-right: .65rem;
    }
  }
</style>