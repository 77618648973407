<template>
  <div class="pc-home pc-navigation-bar-height min-width">
    <!-- <div class="pc-home-head-hint" @click="dialogVisible = true">
      <div class="pc-home-head-time">2022-11-11</div>
      <div class="pc-home-head-announcement">
        公告
      </div>
      <div class="pc-home-head-ext">
        中福艺术平台积极响应国家网络安全和信息化委员会办公室《关于进一步防范和处置虚拟货币交易炒作风险的通知》
      </div>
    </div> -->
    <div class="pc-home-bx">
      <img
        class="pc-home-bx-slideshow"
        src="@/assets/pc/Rectangle 2@2x.png"
        alt=""
      />
      <div class="pc-home-bx-new-hotspot">
        <div class="pc-home-bx-new-hotspot-text">
          Digital Art Fair 2022 圆满结束
        </div>
        <div class="pc-home-bx-new-hotspot-time">2022.11.8</div>
        <el-button
          class="pc-home-bx-new-hotspot-button"
          @click="$router.push('/homeDaf')"
          type="danger"
          >查看详情</el-button
        >
      </div>
      <div class="video">
        <video src="/video/video.mov" muted autoplay height="100%" width="100%" controls />
      </div>
      <div class="pc-home-bx-territory">
        <img src="@/assets/pc/Group 8@2x.png" alt="" />
        <div class="pc-home-bx-territory-title">业务版图</div>
        <div class="pc-home-bx-territory-text">
          中国 | 中国香港 | 中国澳门 | 老挝 | 柬埔寨 | 日本 | 英国
        </div>
      </div>
      <div class="pc-home-bx-hot-sale">
        <div class="pc-home-bx-hot-sale-title">热销系列</div>
        <div class="pc-chinese-paintings">
          <div class="title">中国画系列数字藏品</div>
          <div class="text">
            此系列来自于《贞松永茂》以及《画坛巨擘》两本书画合璧册。拥有书画艺术的珍贵同时凸显中国文化、历史、道德、教育、学识等更方面的价值。从题材看：书法中真、草、棣、隶、篆，绘画中山水、任务、花鸟、走兽，应有尽有。从技法看：工笔、写意、水墨、 设色，诗、词、题、赋，一应俱全。从中可见，二十世纪四十年代中国书画家作品大致面貌，对研究我国近现代画史具有重要参考价值。
          </div>
          <el-button
          class="pc-home-bx-hot-sale-button"
          type="danger"
          @click="marketBtn"
          >查看系列</el-button
        >
        </div>
        
      </div>
      <div class="pc-home-bx-nft">
        <div class="pc-home-bx-nft-title">
          什么是数字艺术藏品
        </div>
        <div class="pc-home-bx-nft-text">
          数字藏品是使用区块链技术进行唯一性标识的经数字化的特定作品、艺术品、有价值的物（含网络虚拟财产、财产性权益等）。
          数字藏品为虚拟商品，您在购买时需特别同意，相关商品不接受退换货。每个数字藏品都映射着特定区块链上的唯一序列号，不可篡改、不可分割，也不能互相替代。每一个数字藏品都代表特定作品、艺术品和商品或其限量发售的单个数字复制品，记录着其不可篡改的链上权利。数字藏品有特定作品、艺术品和商品的实际价值做支撑，不具备支付功能等任何货币属性。
        </div>
      </div>
      <div class="pc-home-bx-advantage">
        <div class="pc-home-bx-advantage-title">数字艺术藏品的优势</div>
        <div class="pc-home-bx-advantage-content">
          <div class="pc-home-bx-advantage-content-list">
            <img src="@/assets/pc/Bitmap Copy@2x.png" alt="" />
            <div class="pc-home-bx-advantage-content-list-title">
              1、版权确权及溯源
            </div>
            <div class="pc-home-bx-advantage-content-list-text">
              通过区块链技术来解决数字艺术品版权和溯源的问题，通过智能合约与业务应用层内实现逻辑处理和数据整合，让版权归属与收入分配的透明度达到新的台阶。
            </div>
          </div>
          <div class="pc-home-bx-advantage-content-list">
            <img
              style="width: 1.19rem; height: 1.09rem;"
              src="@/assets/pc/Bitmap@2xx.png"
              alt=""
            />
            <div class="pc-home-bx-advantage-content-list-title">
              2、为行业降本增效
            </div>
            <div class="pc-home-bx-advantage-content-list-text">
              有效提高权属问题产生版权纠纷的解决效率，因版权区块链证据公信力强，可以减少大量诉讼周期和相关费用，普惠每一位创作者。
            </div>
          </div>
          <div class="pc-home-bx-advantage-content-list">
            <img
              style="width: 1.11rem; height: 1.03rem; margin-bottom: 0.08rem;"
              src="@/assets/pc/Bitmap@2x (1).png"
              alt=""
            />
            <div
              style="margin-bottom: 0.16rem;"
              class="pc-home-bx-advantage-content-list-title"
            >
              3、透明式版权保护
            </div>
            <div class="pc-home-bx-advantage-content-list-text">
              版权保护更加清晰透明，集权益存证、作品确权、数量控制以及盗版防范为一体，让原创者“不出门”实现版权保护。
            </div>
          </div>
          <div class="pc-home-bx-advantage-content-list">
            <img
              style="width: 1.07rem; height: 0.92rem; margin-bottom: 0.19rem;"
              src="@/assets/pc/Bitmap Copy 2@2x.png"
              alt=""
            />
            <div
              style="margin-bottom: 0.16rem;"
              class="pc-home-bx-advantage-content-list-title"
            >
              4、强大的技术支撑
            </div>
            <div class="pc-home-bx-advantage-content-list-text">
              平台立足于区块链技术在知识产权领域的应用，强大的加密算法和云计算能力支持每一次安全可靠的链上互动。
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <div class="announcement-content">
        <img src="@/assets/pc/title@2x.png" alt="" />
        <div class="title"></div>
        <div class="time">2022.11.8</div>
        <div class="text">
          尊敬的用户您好！中福艺术平台为积极响应国家网络安全和信息化委员会办公室《关于进一步防范和处置虚拟货币交易炒作风险的通知》的号召，特对您在我们平台上的数字艺术品交易做温馨提示：本平台中所有数字艺术品均应通过NFT技术将数字摘要信息存储在区块链上，您作为数字艺术品的用户（艺术家、入驻商家、收藏者甚或投资者）应当树立正确的理念，明确数字艺术品是文化的载体而不是金融的工具，准确识别基于NFT技术的数字艺术品交易风险，希望您在收藏、使用、处分、购买、售卖数字艺术品及其相关权利时合理评估自身的风险识别能力和风险承担能力，hunter将与您一同关注数字艺术品交易的相关风险。
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Utils from '@/common/utils.js'
export default {
  data() {
    return {
      dialogVisible: false,
    }
  },
  methods: {
    marketBtn() {
      Utils.$emit('Painting');
      this.$router.push('/market?type=Painting')
      window.scrollTo(0, 0)
    },
  },
}
</script>
<style lang="less" scoped>
.pc-home {
  width: 100%;
  background: url('../../assets/pc/Bitmap@2x.png') no-repeat;
  background-size: 100% 13.81rem;
  .pc-home-head-hint {
    width: 100%;
    height: 0.93rem;
    background: rgba(255, 255, 255, 0.76);
    backdrop-filter: blur(0.08rem);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.19rem;
    cursor: pointer;
    .pc-home-head-announcement {
      border-radius: 0.13rem;
      width: 0.57rem;
      height: 0.27rem;
      // background: linear-gradient(180deg, #FF8787 0%, #FF3030 100%);
      font-size: 0.13rem;
      text-align: center;
      color: #fff;
      line-height: 0.27rem;
      margin-left: 0.49rem;
      margin-right: 0.13rem;
    }
  }
  .pc-home-bx {
    width: 12.4rem;
    margin: 0 auto;
    padding-top: 0.4rem;
    margin-bottom: 2.81rem;
  }
  .pc-home-bx-slideshow {
    width: 12.4rem;
    height: 5.47rem;
    margin-bottom: 0.56rem;
    border-radius: 0.12rem;
  }
  .pc-home-bx-new-hotspot {
    position: relative;
    background: url('../../assets/pc/Group 3@2x.png') no-repeat;
    background-size: 100%;
    width: 100%;
    height: 2.51rem;
    box-sizing: border-box;
    padding-left: 0.35rem;
    padding-top: 0.88rem;
    font-size: 0.19rem;
    border-radius: 0.12rem;
    .pc-home-bx-new-hotspot-text {
      font-size: 0.32rem;
    }
    .pc-home-bx-new-hotspot-button {
      width: 1.73rem;
      height: 0.64rem;
      position: absolute;
      right: 0.35rem;
      bottom: 0.28rem;
      color: #fff;
      font-size: 0.21rem;
      border-radius: 0.5rem;
      text-align: center;
      background: url('../../assets/pc/Group 11@2x.png') no-repeat;
      background-size: 100%;
    }
  }
  .pc-home-bx-territory {
    border-radius: 0.12rem;
    position: relative;
    width: 100%;
    height: 6.36rem;
    margin-bottom: 0.49rem;
    background: url('../../assets/pc/Rectangle 3@2x.png') no-repeat;
    background-size: 100%;
    img {
      position: absolute;
      top: 0.28rem;
      left: 50%;
      transform: translate(-50%, 0);
      width: 6.17rem;
      height: 1.29rem;
    }
    .pc-home-bx-territory-title {
      position: absolute;
      top: 0.51rem;
      left: 50%;
      transform: translate(-50%, 0);
      font-size: 0.45rem;
      font-weight: 900;
    }
    .pc-home-bx-territory-text {
      position: absolute;
      top: 1.17rem;
      left: 50%;
      transform: translate(-50%, 0);
      font-size: 0.19rem;
      font-weight: 400;
    }
  }
  .pc-home-bx-nft {
    width: 100%;
    height: 3.57rem;
    background: url('../../assets/pc/Group 7@2x.png') no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    box-sizing: border-box;
    font-size: 0.19rem;
    line-height: 0.27rem;
    padding: 0.52rem 1.55rem 0.25rem 1.59rem;
    margin-bottom: 0.53rem;
    .pc-home-bx-nft-title {
      font-size: 0.28rem;
      font-weight: 900;
    }
    .pc-home-bx-nft-text {
      width: 9.27rem;
      height: 1.8rem;
    }
  }
  .pc-home-bx-advantage {
    width: 100%;
    height: 8.57rem;
    background: url('../../assets/pc/Group 7 Copy@2x.png') no-repeat;
    background-size: 100% 100%;
    padding: 0.52rem 3.37rem 0 3.17rem;
    box-sizing: border-box;
    border-radius: 0.12rem;
    .pc-home-bx-advantage-title {
      font-size: 0.28rem;
      font-weight: 900;
      color: #ff6060;
      text-align: center;
      margin-bottom: 0.15rem;
    }
    .pc-home-bx-advantage-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .pc-home-bx-advantage-content-list {
        text-align: center;
        img {
          width: 1.13rem;
          height: 1.09rem;
          margin-bottom: 0.29rem;
        }
        .pc-home-bx-advantage-content-list-title {
          margin-bottom: 0.33rem;
          font-size: 0.28rem;
          font-weight: 900;
        }
        .pc-home-bx-advantage-content-list-text {
          width: 2.52rem;
          height: 1.72rem;
          margin-bottom: 0.15rem;
          font-size: 0.19rem;
          font-weight: 400;
          color: #3f3f3f;
          line-height: 0.27rem;
        }
      }
    }
  }
  .announcement-content {
    width: 10.69rem;
    height: 4.75rem;
    box-sizing: border-box;
    padding: 0.64rem 0.77rem 0.68rem 0.65rem;
    img {
      width: 0.89rem;
      height: 0.43rem;
      margin-bottom: 0.2rem;
    }
    .title {
      height: 0.02rem;
      background-color: #f1f1f1;
      margin-bottom: 0.1rem;
    }
    .time {
      font-size: 0.19rem;
      margin-bottom: 0.24rem;
    }
    .text {
      height: 1.8rem;
      font-size: 0.16rem;
      font-weight: 400;
      color: #3f3f3f;
      line-height: 0.23rem;
    }
  }
  /deep/ .el-dialog__header {
    padding: 0;
  }
  /deep/ .el-dialog__body {
    padding: 0;
  }
  /deep/ .el-dialog {
    width: 10.69rem;
    border-radius: 0.11rem;
  }
  .video {
    width: 100%;
    height: 8rem;
    margin-bottom: .57rem;
  }
  .pc-chinese-paintings {
    width: 100%;
    height: 5.17rem;
    box-sizing: border-box;
    background: url('../../assets/pc/LAYER NFT Copy 5.png') no-repeat;
    background-size: 100% 100%;
    position: relative;
    .title {
      position: absolute;
      top: 1.09rem;
      left: .81rem;
      font-weight: 900;
      font-size: .45rem;
    }
    .text {
      width: 5.47rem;
      font-size: .16rem;
      line-height: .23rem;
      position: absolute;
      top: 2.09rem;
      left: .81rem;
    }
    .pc-home-bx-hot-sale-button {
      position: absolute;
      bottom: .69rem;
      left: 4.59rem;
      width: 1.73rem !important;
      height: .64rem !important;
    }
  }
}
</style>
