<template>
  <div>
    <pc-navigation-bar v-if="!$isMobile"></pc-navigation-bar>
    <m-navigation-bar v-else></m-navigation-bar>
    <p-home v-if="!$isMobile"></p-home>
    <m-home v-else></m-home>
    <pc-footer v-if="!$isMobile"></pc-footer>
    <m-footer v-else></m-footer>
  </div>
</template>

<script>
import PHome from '@/components/pc/home.vue'
import MHome from '@/components/mobile/home.vue'
export default {
  components: {
    PHome,
    MHome,
  }
}
</script>

<style>

</style>